.project {
  width: 100%;
  height: auto; /* Change to auto */
  min-height: calc(100vh - 100px); /* Use min-height to ensure a minimum height */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center; /* Center text horizontally */
}

.project h1 {
  margin-top: 20px;
  color: #3e497a;
  font-size: 35px;
}

.project img {
  max-width: 100%;
  max-height: 70vh; /* Limit maximum height */
  border-radius: 10px;
  margin-bottom: 20px;
}

.project p {
  font-size: 40px;
  color: #3e497a;
}

.project svg {
  font-size: 60px;
  color: #3e497a;
}
